import * as React from 'react';
import { Paper, Typography, Container } from '@mui/material';

import { Guest, Booking } from '../models';

import BackgroundImage from '../images/background.jpg';

type Inputs = Guest & Booking;

const SuccessPage = () => {
	return (
		<div
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				minHeight: 'calc(100vh - 120px)',
			}}
		>
			<Container maxWidth='md' sx={{ padding: 3 }}>
				<Paper elevation={3} sx={{ padding: 3 }}>
					<Typography
						variant='h5'
						sx={{ marginBottom: 3, textAlign: 'center' }}
						gutterBottom
					>
						Tudo certo!
					</Typography>

					<Typography
						variant='body1'
						sx={{ marginBottom: 3, textAlign: 'center' }}
					>
						Sua reserva foi realizada com sucesso.
					</Typography>
				</Paper>
			</Container>
		</div>
	);
};

export default SuccessPage;
